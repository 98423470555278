export const cadeaux = [
    {
      "titre": "Pistolet à balle pour chat",
      "lien": "https://amzn.to/4fUqMVu",
      "image": "https://m.media-amazon.com/images/I/61r62eq2nfL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/61Zn+MFm7tL._AC_SL1500_.jpg",
      "prix": "9,99",
      "isBlackFriday": "FALSE",
      "percentBlackFriday": "0,00",
      "PrixBeforePromo": "9,99",
      "description": "Transformez les moments de jeu de votre chat en une véritable chasse amusante ! Ce pistolet à balle projette des balles légères pour stimuler l'instinct naturel de votre félin. Compact, facile à utiliser, et idéal pour renforcer votre lien avec votre compagnon à fourrure."
    },
    {
      "titre": "Tapis tunnel pour chat",
      "lien": "https://amzn.to/3ZcOZz9",
      "image": "https://m.media-amazon.com/images/I/71xcWSvFLML._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/71CfNsaInfL._AC_SL1500_.jpg",
      "prix": "31,98",
      "isBlackFriday": "FALSE",
      "percentBlackFriday": "",
      "PrixBeforePromo": "31,98",
      "description": "Offrez à votre chat des heures d'exploration et de cache-cache avec ce tapis tunnel multifonction ! Doux et pliable, il est parfait pour satisfaire sa curiosité et lui créer un espace ludique et confortable."
    },
    {
      "titre": "Arbre à chat",
      "lien": "https://amzn.to/3CQPcAs",
      "image": "https://m.media-amazon.com/images/I/810NQPqAv5L._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/81jF7wNIuhL._AC_SL1500_.jpg",
      "prix": "39,99",
      "isBlackFriday": "TRUE",
      "percentBlackFriday": "26,00",
      "PrixBeforePromo": "54,04",
      "description": "Donnez à votre chat un royaume digne de sa majesté ! Cet arbre à chat propose plusieurs niveaux, des plateformes confortables et un griffoir pour qu’il s’amuse, se détende et garde ses griffes en parfait état. Une offre à ne pas manquer !"
    },
    {
      "titre": "Sac à dos pour chat",
      "lien": "https://amzn.to/4i9u20y",
      "image": "https://m.media-amazon.com/images/I/51+O3h8xM-L._AC_SL1000_.jpg",
      "image2": "https://m.media-amazon.com/images/I/51flCXRSzNL._AC_SL1000_.jpg",
      "prix": "68,99",
      "isBlackFriday": "FALSE",
      "percentBlackFriday": "",
      "PrixBeforePromo": "68,99",
      "description": "Voyagez avec votre chat en toute sérénité grâce à ce sac à dos ergonomique et spacieux. Ses fenêtres transparentes et son design ventilé garantissent confort et sécurité pour votre compagnon, où que vous alliez."
    },
    {
      "titre": "Kit d'empreinte pour chat",
      "lien": "https://amzn.to/4gc7RoA",
      "image": "https://m.media-amazon.com/images/I/61ARZOaNIOL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/61hKoZYssdL._AC_SL1500_.jpg",
      "prix": "24,99",
      "isBlackFriday": "FALSE",
      "percentBlackFriday": "",
      "PrixBeforePromo": "24,99",
      "description": "Immortalisez les petites pattes de votre chat avec ce kit facile à utiliser. Un souvenir unique et émouvant pour garder un morceau de son histoire pour toujours. Idéal comme cadeau ou décoration sentimentale."
    },
    {
      "titre": "Fontaine à eau automatique",
      "lien": "https://amzn.to/4i9tW9c",
      "image": "https://m.media-amazon.com/images/I/61ekJbPqjFL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/715OEYMIRHL._AC_SL1500_.jpg",
      "prix": "17,00",
      "isBlackFriday": "TRUE",
      "percentBlackFriday": "6,00",
      "PrixBeforePromo": "18,09",
      "description": "Assurez l’hydratation optimale de votre chat avec cette fontaine à eau automatique. Silencieuse et pratique, elle offre une eau fraîche et filtrée en continu pour la santé de votre compagnon."
    },
    {
      "titre": "Laser Automatique pour chat",
      "lien": "https://amzn.to/4g7ViKV",
      "image": "https://m.media-amazon.com/images/I/61YurDhD1dL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/71YpnRm0Y9L._AC_SL1500_.jpg",
      "prix": "23,79",
      "isBlackFriday": "TRUE",
      "percentBlackFriday": "15,00",
      "PrixBeforePromo": "27,99",
      "description": "Laissez votre chat s'amuser et se dépenser avec ce laser automatique qui bouge de manière aléatoire. Idéal pour stimuler son instinct de chasseur et le divertir, même en votre absence !"
    },
    {
      "titre": "Ornement de Noël Personnalisé",
      "lien": "https://amzn.to/4g7VfyJ",
      "image": "https://m.media-amazon.com/images/I/818fQ2hQ9PL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/812jB+PRvXL._AC_SL1500_.jpg",
      "prix": "11,99",
      "isBlackFriday": "FALSE",
      "percentBlackFriday": "0,00",
      "PrixBeforePromo": "11,99",
      "description": "Ajoutez une touche unique à votre sapin avec cet ornement de Noël personnalisé. Un cadeau parfait pour les amoureux des chats ou pour immortaliser l'esprit des fêtes avec votre compagnon félin."
    },
    {
      "titre": "Jouet en bois",
      "lien": "https://amzn.to/3ViFUDB",
      "image": "https://m.media-amazon.com/images/I/61Ru5J4rpqL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/71bbMtmNEQL._AC_SL1500_.jpg",
      "prix": "18,39",
      "isBlackFriday": "TRUE",
      "percentBlackFriday": "20,00",
      "PrixBeforePromo": "22,99",
      "description": "Faites plaisir à votre chat avec ce jouet en bois écologique et robuste. Conçu pour durer, il stimule l’intelligence et la curiosité de votre félin tout en respectant la planète."
    },
    {
      "titre": "Planche à gratter",
      "lien": "https://amzn.to/4ifSs8P",
      "image": "https://m.media-amazon.com/images/I/71u0UFQwvWL._AC_SL1500_.jpg",
      "image2": "https://m.media-amazon.com/images/I/81lTlFthfIL._AC_SL1500_.jpg",
      "prix": "30,42",
      "isBlackFriday": "TRUE",
      "percentBlackFriday": "15,00",
      "PrixBeforePromo": "35,79",
      "description": "Protégez vos meubles tout en faisant plaisir à votre chat avec cette planche à gratter durable. Ergonomique et design, elle s’intègre parfaitement à votre intérieur tout en offrant un espace idéal pour les griffades."
    }
  ]
  