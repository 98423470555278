import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { blog } from '../data/blog';

function BlogPost() {
  const { id } = useParams();
  const post = blog.find(item => item.id === parseInt(id) || item.id === id);

  console.log("BlogPost - post:", post);
  console.log("BlogPost - image URL:", post?.image);

  const imagePath = post?.image ? `/${post.image}` : null;

  return (
    <>
      <Helmet>
        <title>{post.titre} - Pour Mon Chat</title>
        <meta name="description" content={post.contenu.substring(0, 150)} />
      </Helmet>

      <Header />
      <div className="blog-post-container">
        <h1>{post.titre}</h1>
        {post.image && (
          <img 
            src={imagePath}
            alt={post.titre}
            className="blog-post-image"
            onError={(e) => {
              console.log("Erreur de chargement de l'image:", e);
              console.log("Chemin de l'image tenté:", imagePath);
            }}
          />
        )}
        <div className="blog-post-content">
          {post.contenu}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogPost;
