export const blog = [
    {
        "id": 1,
        "titre": "Pourquoi offrir un cadeau à votre chat ?",
        "image": "blog1.jpg",
        "contenu": "Votre chat, ce membre à part entière de votre famille, mérite toute votre attention. Qu'il vous accueille avec des ronronnements, se faufile sur vos genoux pour un câlin ou vous amuse par ses facéties, il embellit votre quotidien. Alors pourquoi ne pas lui rendre un peu de cet amour en lui offrant un cadeau ?\n\n" +
        "Un cadeau pour renforcer le lien humain-chat\n\n" +
        "Offrir un cadeau à votre chat, c'est bien plus qu'un simple geste matériel. Cela montre à votre compagnon à quel point vous tenez à lui. Les chats sont des animaux sensibles : ils sentent l'amour et l'attention qu'on leur porte. Offrir un jouet, un accessoire ou même une simple friandise peut renforcer votre lien avec lui. C'est une façon de lui dire : \"Tu es important pour moi.\"\n\n" +
        "Les bienfaits des cadeaux pour le bien-être du chat\n\n" +
        "Un cadeau peut également jouer un rôle essentiel dans le bien-être physique et mental de votre chat. Voici quelques exemples :\n\n" +
        "• Stimuler son instinct naturel : Les jouets interactifs, comme les balles ou les cannes à plumes, réveillent son instinct de chasse.\n" +
        "• Lutter contre l'ennui : Si votre chat passe beaucoup de temps seul à la maison, des jouets intelligents ou des arbres à chat enrichis peuvent l'occuper et l'amuser.\n" +
        "• Encourager l'exercice : Un chat actif est un chat heureux et en meilleure santé.\n" +
        "• Offrir du confort : Des accessoires comme des coussins moelleux ou des cachettes douillettes lui offriront des moments de détente absolue.\n\n" +
        "Quand offrir un cadeau à son chat ?\n\n" +
        "Il n'y a pas de règle ! Les fêtes comme Noël ou l'anniversaire de votre chat sont des occasions idéales, mais une petite surprise en dehors de ces moments spéciaux est tout aussi appréciée. Vous pouvez même instaurer un rituel en lui offrant une nouveauté chaque mois pour le surprendre et varier ses activités.\n\n" +
        "Prêt(e) à gâter votre chat ? Dans nos prochains articles, découvrez des idées de cadeaux qui feront vibrer votre compagnon à quatre pattes."
    },
    {
        "id": 2,
        "titre": "Les 5 jouets qui rendent les chats fous de joie !",
        "image": "blog2.jpg",
        "contenu": "Vous remarquez que votre chat s'intéresse un peu trop à vos rideaux ou qu'il passe son temps à dormir ? Il est peut-être temps de lui offrir un jouet qui l'occupera et l'amusera ! Après tout, jouer est essentiel pour la santé mentale et physique de nos amis félins. Mais pas n'importe quel jouet ! Nous avons sélectionné pour vous 5 incontournables testés et approuvés par les chats (et leurs humains).\n\n" +
        "1. La canne à plumes : un indétrônable\n" +
        "Si votre chat aime chasser, la canne à plumes sera son jouet préféré. Ce jouet simule le mouvement d'un oiseau, réveillant immédiatement son instinct de prédateur. De plus, c'est une excellente occasion de jouer avec votre chat et de renforcer votre lien avec lui.\n\n" +
        "2. Le circuit interactif avec balle lumineuse\n" +
        "Ce jouet combine mouvement, lumière et interactivité. Le principe est simple : une balle roule à l'intérieur d'un circuit fermé, et votre chat doit la pousser pour la faire bouger. Non seulement cela stimule son esprit, mais cela l'incite aussi à bouger, idéal pour les chats un peu paresseux.\n\n" +
        "3. Les souris en peluche garnies d'herbe à chat\n" +
        "Rien de tel qu'un jouet qui sent bon l'herbe à chat pour captiver votre compagnon. Les petites souris en peluche sont parfaites pour les chats qui adorent mordre, porter des objets dans leur bouche ou tout simplement jouer à les lancer en l'air.\n\n" +
        "4. Le tunnel pliable\n" +
        "Les chats adorent se cacher, bondir et explorer. Un tunnel pliable est une merveilleuse cachette où ils peuvent courir, se cacher ou s'amuser avec d'autres jouets. Certains modèles incluent des balles suspendues ou des ouvertures supplémentaires pour encore plus de plaisir.\n\n" +
        "5. Le puzzle distributeur de friandises\n" +
        "Stimulez l'intelligence de votre chat tout en lui offrant une récompense savoureuse. Ces jouets nécessitent qu'il réfléchisse et interagisse pour obtenir une friandise. Parfait pour le maintenir actif et satisfait !\n\n" +
        "Envie de tester un de ces jouets ? Retrouvez nos recommandations dans notre boutique dédiée et faites plaisir à votre chat dès aujourd'hui."
    },
    {
        "id": 3,
        "titre": "DIY : Fabriquez un cadeau original pour votre chat !",
        "image": "blog3.jpg",
        "contenu": "Un cadeau DIY (Do It Yourself) pour votre chat, c'est l'occasion de lui offrir quelque chose de personnalisé, unique, et créé avec amour. En plus, cela vous permet de faire des économies et de recycler des matériaux que vous avez déjà chez vous. Pas besoin d'être un(e) expert(e) en bricolage, ces idées sont simples et accessibles à tous.\n\n" +
        "1. Une balle maison avec une chaussette usée\n" +
        "Prenez une vieille chaussette, remplissez-la avec du papier froissé (pour le bruit) ou un peu d'herbe à chat, puis refermez-la avec un nœud solide. Vous obtenez un jouet sonore et olfactif qui attirera immédiatement l'attention de votre chat.\n\n" +
        "2. Un arbre à chat minimaliste avec des boîtes en carton\n" +
        "Empilez plusieurs boîtes en carton de tailles différentes pour créer un \"arbre à chat\" improvisé. Découpez des trous pour permettre à votre chat d'explorer et de grimper. Si vous voulez aller plus loin, vous pouvez peindre les boîtes ou les recouvrir de tissu pour un rendu esthétique.\n\n" +
        "3. Une canne à pêche avec des objets du quotidien\n" +
        "Avec une baguette (ou un bâton), un morceau de ficelle, et un petit objet léger (comme un bouchon de liège ou une plume), vous pouvez fabriquer une canne à pêche maison. Les mouvements imprévisibles de votre création captiveront votre chat à coup sûr.\n\n" +
        "4. Un coussin en tissu recyclé garni d'herbe à chat\n" +
        "Prenez un vieux t-shirt ou un morceau de tissu, découpez-le en forme de coussin, remplissez-le d'herbe à chat et cousez les bords. Votre chat adorera s'y frotter ou le mordiller.\n\n" +
        "Partager vos créations !\n" +
        "Si vous réalisez l'un de ces projets, n'hésitez pas à le partager avec notre communauté. Envoyez-nous vos photos ou taguez-nous sur Instagram avec le hashtag #CadeauxPourChatsDIY. Qui sait, votre création pourrait inspirer d'autres amoureux des chats !"
    }

]