import Header from '../components/Header';
import Footer from '../components/Footer';
import { cadeaux } from '../data/top10';
import { Helmet } from 'react-helmet-async';

function Home() {

  return (
    <>
      <Helmet>
        <title>Pour Mon Chat | Guide des Meilleurs Accessoires Chat 2024</title>
        <meta name="description" content="Découvrez les meilleurs accessoires et cadeaux pour chat : jouets, arbres à chat, fontaines à eau et plus encore. Guide d'achat expert pour gâter votre félin." />
        <link rel="canonical" href="https://www.pourmonchat.fr" />
        <meta
          name="Pour Mon Chat"
          content="Découvrez les meilleures idées cadeau pour chats et leurs propriétaires : jouets, accessoires, et indispensables pour chouchouter votre félin préféré. Trouvez le cadeau parfait pour chaque occasion !"
        />
      </Helmet>

      <main>
      <Header />
        <h1 className="welcome-title">Guide des Meilleurs Accessoires et Cadeaux pour Chat</h1>
        
        <section className="intro-section">
          <h2 className="intro-question">Vous cherchez le cadeau parfait pour votre chat ?</h2>
          <p className="intro-description">
            Découvrez notre sélection experte d'accessoires et jouets pour chats. 
            Des arbres à chat aux fontaines à eau, en passant par les jouets 
            interactifs, trouvez l'accessoire idéal pour le bonheur de votre félin.
          </p>
        </section>

        <section className="recommended-gifts">
          <h2>Top 10 des Meilleurs Accessoires pour Chat en 2024</h2>
          <div className="gifts-container">
            <div className="gifts-list">
              {cadeaux.map((gift, index) => (
                <div key={index} className="gift-item">
                  <h3 className="gift-title">#{index + 1} {gift.titre}</h3>
                  <div className="gift-content">
                    <div className="gift-image">
                      <img 
                        src={gift.image} 
                        alt={`${gift.titre} pour chat - Top ${index + 1} des meilleurs accessoires pour chat 2024 ${gift.isBlackFriday ? `- Promotion -${gift.percentBlackFriday}%` : ''}`}
                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                        loading="lazy"
                      />
                    </div>
                    <div className="gift-details">
                      <p className="gift-desc">{gift.description}</p>
                      <div className="price-buy-container">
                        <div className="price-vendor">
                          <p className="gift-price">{gift.price}</p>
                          <p className="vendor">sur <a href="https://amzn.to/41bBFgN" target="_blank" rel="sponsored noopener" className="vendor-name">Amazon</a></p>
                        </div>
                        <a href={gift.lien} target="_blank" rel="sponsored noopener" className="buy-button">Acheter</a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Home;
