import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundImage: "url('/chatfooter.jpg')" }}>
      <div className="footer-content">
        <div className="footer-section">
          <h4>Pour Mon Chat</h4>
          <p>Le meilleur pour votre félin</p>
        </div>
        
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: contact@pourmonchat.fr</p>
        </div>
        
        <div className="footer-section">
          <h4>Information</h4>
          <p className="affiliate-notice">Ce site utilise des liens d'affiliation Amazon. Les prix restent identiques pour vous.</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Pour Mon Chat. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
