import { useState } from 'react';
import { cadeaux } from '../data/cadeaux';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';

function BlackFriday() {
    const [filtrePrix, setFiltrePrix] = useState(null);
    
    const filtrerProduits = (produit) => {
        if (!filtrePrix) return true;
        
        // Convertir le prix en nombre
        const prixNumerique = parseFloat(produit.prix);
        
        switch (filtrePrix) {
          case '0-10':
            return prixNumerique < 10;
          case '10-20':
            return prixNumerique >= 10 && prixNumerique < 20;
          case '20-30':
            return prixNumerique >= 20 && prixNumerique < 30;
          case '30-50':
            return prixNumerique >= 30 && prixNumerique < 50;
          case '50+':
            return prixNumerique >= 50;
          default:
            return true;
        }
      };

    return (
        <>
            <Helmet>
                <title>Black Friday Chat 2024 | Promotions Accessoires et Jouets</title>
                <meta name="description" content="Découvrez les meilleures offres Black Friday pour les accessoires et jouets pour chats. Profitez des promotions exclusives et des meilleurs prix pour gâter votre félin." />
                <link rel="canonical" href="https://www.pourmonchat.fr/blackfriday" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.pourmonchat.fr/blackfriday" />
                <meta property="og:title" content="Promotions Chat 2024 | Black Friday Accessoires et Jouets" />
                <meta property="og:description" content="Découvrez les meilleures offres Black Friday pour les accessoires et jouets pour chats. Profitez des promotions exclusives pour gâter votre félin." />
                <meta property="og:image" content="https://www.pourmonchat.fr/blackfriday-banner.jpg" />
                <meta property="og:locale" content="fr_FR" />
                <meta property="og:site_name" content="Pour Mon Chat" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SpecialAnnouncement",
                        "name": "Promotions Black Friday pour Accessoires Chat",
                        "text": "Découvrez nos offres Black Friday sur les accessoires et jouets pour chats",
                        "category": "https://schema.org/SaleEvent",
                        "datePosted": "2024-03-20",
                        "expires": "2024-12-31",
                        "url": "https://www.pourmonchat.fr/blackfriday",
                        "announcementLocation": {
                            "@type": "WebPage",
                            "name": "Pour Mon Chat - Black Friday",
                            "url": "https://www.pourmonchat.fr/blackfriday"
                        }
                    })}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "ItemList",
                        "itemListElement": cadeaux
                            .filter(cadeau => cadeau.isBlackFriday)
                            .map((cadeau, index) => ({
                                "@type": "Product",
                                "position": index + 1,
                                "name": cadeau.titre,
                                "description": "Accessoire pour chat en promotion Black Friday",
                                "image": cadeau.image,
                                "url": cadeau.lien,
                                "offers": {
                                    "@type": "Offer",
                                    "price": cadeau.prix,
                                    "priceCurrency": "EUR",
                                    "priceValidUntil": "2024-12-31",
                                    "availability": "https://schema.org/InStock"
                                }
                            }))
                    })}
                </script>
            </Helmet>
            <div className="App">
                <Header />
                <div className="intro-section">
                    <h2 className="promo-title">Promotions Black Friday pour Chats 2024</h2>
                    <h3>Accessoires et Jouets pour Chat en Promotion</h3>
                    <p className="intro-description">
                        Découvrez notre sélection d'accessoires et jouets pour chats à prix réduits. 
                        Des promotions exceptionnelles sur les meilleurs produits pour félins : arbres à chat, 
                        jouets interactifs, accessoires de confort et bien plus encore !
                    </p>
                </div>

                <div className="filtres-prix">
                <button 
                    className={`filtre-btn ${filtrePrix === '0-10' ? 'actif' : ''}`}
                    onClick={() => setFiltrePrix(filtrePrix === '0-10' ? null : '0-10')}
                >
                    - 10€
                </button>
                <button 
                    className={`filtre-btn ${filtrePrix === '10-20' ? 'actif' : ''}`}
                    onClick={() => setFiltrePrix(filtrePrix === '10-20' ? null : '10-20')}
                >
                    10€ - 20€
                </button>
                <button 
                    className={`filtre-btn ${filtrePrix === '20-30' ? 'actif' : ''}`}
                    onClick={() => setFiltrePrix(filtrePrix === '20-30' ? null : '20-30')}
                >
                    20€ - 30€
                </button>
                <button 
                    className={`filtre-btn ${filtrePrix === '30-50' ? 'actif' : ''}`}
                    onClick={() => setFiltrePrix(filtrePrix === '30-50' ? null : '30-50')}
                >
                    30€ - 50€
                </button>
                <button 
                    className={`filtre-btn ${filtrePrix === '50+' ? 'actif' : ''}`}
                    onClick={() => setFiltrePrix(filtrePrix === '50+' ? null : '50+')}
                >
                    + 50€
                </button>
            </div>

                <main className="produits-container">
                    <div className="produits-grid">
                    {cadeaux
                        .filter(cadeau => cadeau.isBlackFriday)
                        .filter(filtrerProduits)
                        .map((cadeau, index) => (
                        <a 
                            href={cadeau.lien} 
                            target="_blank" 
                            rel="sponsored noopener" 
                            key={index} 
                            className="carte-produit"
                        >
                            <div className="image-container">
                            {cadeau.isBlackFriday && (
                                <div className="bandeau-blackfriday">
                                Offre Black Friday
                                </div>
                            )}
                            <img 
                                src={cadeau.image} 
                                alt={`${cadeau.titre} pour chat - Promotion Black Friday -${cadeau.percentBlackFriday}% - Prix : ${cadeau.prix}€ au lieu de ${cadeau.PrixBeforePromo}€`}
                                className="image-produit image-principale" 
                                loading="lazy"
                            />
                            {cadeau.image2 && (
                                <img 
                                    src={cadeau.image2} 
                                    alt={`${cadeau.titre} pour chat - Vue détaillée - Accessoire félin en promotion Black Friday -${cadeau.percentBlackFriday}%`}
                                    className="image-produit image-secondaire" 
                                    loading="lazy"
                                />
                            )}
                            </div>
                            <div className="produit-info">
                            <div className="produit-details">
                                <div className="titre-container">
                                <h3 className="produit-titre">{cadeau.titre}</h3>
                                {cadeau.isBlackFriday && (
                                    <p className="blackfriday-message">Profitez de l'offre Black Friday</p>
                                )}
                                </div>
                                <div className="prix-container">
                                {cadeau.isBlackFriday ? (
                                    <>
                                    <p className="produit-prix-original">{cadeau.PrixBeforePromo}€</p>
                                    <p className="produit-prix">{cadeau.prix}€</p>
                                    </>
                                ) : (
                                    <p className="produit-prix">{cadeau.prix}€</p>
                                )}
                                </div>
                            </div>
                            <p className="produit-description">{cadeau.description}</p>
                            </div>
                        </a>
                    ))}
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}

export default BlackFriday;
