export const cadeaux = [
  {
    "titre": "Un Hamac pliable pour votre chat !",
    "lien": "https://amzn.to/3B5u48W",
    "image": "https://m.media-amazon.com/images/I/71BLsJlE+kL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81xRmWs-bPL._AC_SL1500_.jpg",
    "prix": "32,00",
    "isBlackFriday": true,
    "percentBlackFriday": "10,00",
    "PrixBeforePromo": "35,56"
  },
  {
    "titre": "Balle de Jouets interactifs",
    "lien": "https://amzn.to/3ZtNj5w",
    "image": "https://m.media-amazon.com/images/I/71G2o8cQvwL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61tKT3zLpaL._AC_SL1500_.jpg",
    "prix": "19,99",
    "isBlackFriday": true,
    "percentBlackFriday": "20,00",
    "PrixBeforePromo": "24,99"
  },
  {
    "titre": "2 Jouets interactif avec lumière",
    "lien": "https://amzn.to/3ZsFMnx",
    "image": "https://m.media-amazon.com/images/I/51ow+EFAOoL._AC_SL1000_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61CsPBzKfqL._AC_SL1000_.jpg",
    "prix": "19,69",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "19,69"
  },
  {
    "titre": "Lot de 7 Jouets pour Chat",
    "lien": "https://amzn.to/3Zu08wI",
    "image": "https://m.media-amazon.com/images/I/81IpWV5IZrS._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81QuA4LlGOL._AC_SL1500_.jpg",
    "prix": "5,91",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "5,91"
  },
  {
    "titre": "Herbe à Chat en forme de jouet",
    "lien": "https://amzn.to/4fPFxsj",
    "image": "https://m.media-amazon.com/images/I/61kLjmdPUbL._AC_SL1000_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61-uI2sE-LL._AC_SL1000_.jpg",
    "prix": "7,49",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "7,49"
  },
  {
    "titre": "Coussin pour chat",
    "lien": "https://amzn.to/3ARpYS0",
    "image": "https://m.media-amazon.com/images/I/81uVE1QDTGL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/713-7uAd3UL._AC_SL1500_.jpg",
    "prix": "9,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "9,99"
  },
  {
    "titre": "Rat télécommandé",
    "lien": "https://amzn.to/4eRntgo",
    "image": "https://m.media-amazon.com/images/I/71f67UkHdWL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/715jRJd7vIL._AC_SL1200_.jpg",
    "prix": "14,00",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "14,00"
  },
  {
    "titre": "Pistolet à balle pour chat",
    "lien": "https://amzn.to/4fUqMVu",
    "image": "https://m.media-amazon.com/images/I/61r62eq2nfL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61Zn+MFm7tL._AC_SL1500_.jpg",
    "prix": "9,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "9,99"
  },
  {
    "titre": "Coussin auto-chauffant",
    "lien": "https://amzn.to/3CRjFyf",
    "image": "https://m.media-amazon.com/images/I/61LoBt6pMNL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81SSH6NxcFL._AC_SL1500_.jpg",
    "prix": "19,98",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "19,98"
  },
  {
    "titre": "Laser Automatique pour chat",
    "lien": "https://amzn.to/4g7ViKV",
    "image": "https://m.media-amazon.com/images/I/61YurDhD1dL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71YpnRm0Y9L._AC_SL1500_.jpg",
    "prix": "23,79",
    "isBlackFriday": true,
    "percentBlackFriday": "15,00",
    "PrixBeforePromo": "27,99"
  },
  {
    "titre": "6 Bâtonnets d'herbe à chat",
    "lien": "https://amzn.to/3ZwaQmx",
    "image": "https://m.media-amazon.com/images/I/71I4LIhoCqL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71Qx+P1A0FL._AC_SL1500_.jpg",
    "prix": "7,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "7,99"
  },
  {
    "titre": "Balle interactive",
    "lien": "https://amzn.to/41dtpgn",
    "image": "https://m.media-amazon.com/images/I/61E95tEHG+L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71l2FIFOpPL._AC_SL1500_.jpg",
    "prix": "10,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "10,99"
  },
  {
    "titre": "Ornement de Noël Personnalisé",
    "lien": "https://amzn.to/4g7VfyJ",
    "image": "https://m.media-amazon.com/images/I/818fQ2hQ9PL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/812jB+PRvXL._AC_SL1500_.jpg",
    "prix": "11,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "11,99"
  },
  {
    "titre": "32 Jouets pour chat",
    "lien": "https://amzn.to/4gcS6Or",
    "image": "https://m.media-amazon.com/images/I/713U0pLdayL._AC_SL1000_.jpg",
    "image2": "https://m.media-amazon.com/images/I/619donxixzL._AC_SL1000_.jpg",
    "prix": "18,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "18,99"
  },
  {
    "titre": "Peluches d'herbe à chat séchée",
    "lien": "https://amzn.to/3Vgiu1A",
    "image": "https://m.media-amazon.com/images/I/91xyWqm3tsL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/A1yZvpGvvTL._AC_SL1500_.jpg",
    "prix": "18,99",
    "isBlackFriday": false,
    "percentBlackFriday": "0,00",
    "PrixBeforePromo": "18,99"
  },
  {
    "titre": "Jouets à plumes interactif",
    "lien": "https://amzn.to/3ViFWLJ",
    "image": "https://m.media-amazon.com/images/I/61HB0kh9E0L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61U0RVKMdwL._AC_SL1500_.jpg",
    "prix": "11,19",
    "isBlackFriday": true,
    "percentBlackFriday": "20,00",
    "PrixBeforePromo": "13,99"
  },
  {
    "titre": "Jouet en bois",
    "lien": "https://amzn.to/3ViFUDB",
    "image": "https://m.media-amazon.com/images/I/61Ru5J4rpqL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71bbMtmNEQL._AC_SL1500_.jpg",
    "prix": "18,39",
    "isBlackFriday": true,
    "percentBlackFriday": "20,00",
    "PrixBeforePromo": "22,99"
  },
  {
    "titre": "Jouet de 4 boules d'herbe à chat",
    "lien": "https://amzn.to/4eULT8w",
    "image": "https://m.media-amazon.com/images/I/71H+rXkAo7L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71zUvInxDOL._AC_SL1500_.jpg",
    "prix": "13,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "13,99"
  },
  {
    "titre": "Distributteurs de croquettes automatique",
    "lien": "https://amzn.to/3CMUTzc",
    "image": "https://m.media-amazon.com/images/I/41GqObEr9ML._AC_SL1001_.jpg",
    "image2": "https://m.media-amazon.com/images/I/619eo9FjfgL._AC_SL1500_.jpg",
    "prix": "109,00",
    "isBlackFriday": true,
    "percentBlackFriday": "45,00",
    "PrixBeforePromo": "198,18"
  },
  {
    "titre": "Double bol surélevé",
    "lien": "https://amzn.to/3OyOzhw",
    "image": "https://m.media-amazon.com/images/I/61afBaXiOqL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71vjLZ2OoZL._AC_SL1500_.jpg",
    "prix": "20,99",
    "isBlackFriday": true,
    "percentBlackFriday": "25,00",
    "PrixBeforePromo": "27,99"
  },
  {
    "titre": "Litière autonettoyante",
    "lien": "https://amzn.to/4eWf2QR",
    "image": "https://m.media-amazon.com/images/I/61LSN9Hwj4L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/51mvMjFVyuL._AC_SL1500_.jpg",
    "prix": "479,00",
    "isBlackFriday": true,
    "percentBlackFriday": "20,00",
    "PrixBeforePromo": "598,75"
  },
  {
    "titre": "Kit d'empreinte pour chat",
    "lien": "https://amzn.to/4gc7RoA",
    "image": "https://m.media-amazon.com/images/I/61ARZOaNIOL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61hKoZYssdL._AC_SL1500_.jpg",
    "prix": "24,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "24,99"
  },
  {
    "titre": "Planche à gratter",
    "lien": "https://amzn.to/4ifSs8P",
    "image": "https://m.media-amazon.com/images/I/71u0UFQwvWL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81lTlFthfIL._AC_SL1500_.jpg",
    "prix": "30,42",
    "isBlackFriday": true,
    "percentBlackFriday": "15,00",
    "PrixBeforePromo": "35,79"
  },
  {
    "titre": "Station d'alimentation personalisée",
    "lien": "https://amzn.to/3CSuYGz",
    "image": "https://m.media-amazon.com/images/I/71xfRWjhGhL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81GqKJ7T2vL._AC_SL1500_.jpg",
    "prix": "29,95",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "29,95"
  },
  {
    "titre": "Jouet 4 en 1",
    "lien": "https://amzn.to/3ZdS0yV",
    "image": "https://m.media-amazon.com/images/I/719eOVbIxDL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71mNRNR5BFL._AC_SL1500_.jpg",
    "prix": "26,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "26,99"
  },
  {
    "titre": "Panier super confortable",
    "lien": "https://amzn.to/4g1ELZ8",
    "image": "https://m.media-amazon.com/images/I/71lrF5t3XpL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/718EzByvY8L._AC_SL1500_.jpg",
    "prix": "12,89",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "12,89"
  },
  {
    "titre": "Arbre à chat moderne",
    "lien": "https://amzn.to/4icbe0v",
    "image": "https://m.media-amazon.com/images/I/61SWWGqpOGL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81nXRcvbuOL._AC_SL1500_.jpg",
    "prix": "116,89",
    "isBlackFriday": true,
    "percentBlackFriday": "10,00",
    "PrixBeforePromo": "129,88"
  },
  {
    "titre": "Pâte à lécher",
    "lien": "https://amzn.to/3Bhq3hI",
    "image": "https://m.media-amazon.com/images/I/61lZMcCqHWL._AC_SL1000_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61ec3fOXO1L._AC_SL1000_.jpg",
    "prix": "43,39",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "43,39"
  },
  {
    "titre": "2 colliers de Noël",
    "lien": "https://amzn.to/4if3BH0",
    "image": "https://m.media-amazon.com/images/I/713onz3uCqL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81uR4kUSLYL._AC_SL1500_.jpg",
    "prix": "9,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "9,99"
  },
  {
    "titre": "Brosse murale avec herbe à chat",
    "lien": "https://amzn.to/3ATWjHT",
    "image": "https://m.media-amazon.com/images/I/71Nr8yf248L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81QjDTKHHTL._AC_SL1500_.jpg",
    "prix": "11,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "11,99"
  },
  {
    "titre": "Tunnel de jeu",
    "lien": "https://amzn.to/49cewNk",
    "image": "https://m.media-amazon.com/images/I/61hp86d5v+L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81LagI7ajRL._AC_SL1500_.jpg",
    "prix": "20,95",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "20,95"
  },
  {
    "titre": "Costume de lion",
    "lien": "https://amzn.to/4gdgdwr",
    "image": "https://m.media-amazon.com/images/I/81INmRLxycL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/A1PaIFoQA8L._AC_SL1500_.jpg",
    "prix": "7,43",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "7,43"
  },
  {
    "titre": "Costume de père Noël",
    "lien": "https://amzn.to/3CQO286",
    "image": "https://m.media-amazon.com/images/I/61JkeMMUjQL._AC_SL1200_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61y8Y36ylvL._AC_SL1200_.jpg",
    "prix": "11,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "11,99"
  },
  {
    "titre": "Déguisement de Noël",
    "lien": "https://amzn.to/4g89Iec",
    "image": "https://m.media-amazon.com/images/I/61KKsEpOSbL._AC_SL1100_.jpg",
    "image2": "https://m.media-amazon.com/images/I/514s4vdMUbL._AC_SL1100_.jpg",
    "prix": "7,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "7,99"
  },
  {
    "titre": "Déguisement cerf de Noël",
    "lien": "https://amzn.to/4g6N45V",
    "image": "https://m.media-amazon.com/images/I/71wB8ZTe+dL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71ZOzQkwrYL._AC_SL1500_.jpg",
    "prix": "8,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "8,99"
  },
  {
    "titre": "Brosse autonettoyante",
    "lien": "https://amzn.to/4g8zK0X",
    "image": "https://m.media-amazon.com/images/I/71hnXEt1ZsL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/7136Pq9aRtL._AC_SL1500_.jpg",
    "prix": "10,12",
    "isBlackFriday": true,
    "percentBlackFriday": "16,00",
    "PrixBeforePromo": "12,05"
  },
  {
    "titre": "Fontaine à eau automatique",
    "lien": "https://amzn.to/4i9tW9c",
    "image": "https://m.media-amazon.com/images/I/61ekJbPqjFL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/715OEYMIRHL._AC_SL1500_.jpg",
    "prix": "17,00",
    "isBlackFriday": true,
    "percentBlackFriday": "6,00",
    "PrixBeforePromo": "18,09"
  },
  {
    "titre": "Griffoir",
    "lien": "https://amzn.to/3CQPkzW",
    "image": "https://m.media-amazon.com/images/I/51Db+BT9n9L._AC_SL1019_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61QO5rljRML._AC_SL1500_.jpg",
    "prix": "13,03",
    "isBlackFriday": true,
    "percentBlackFriday": "40,00",
    "PrixBeforePromo": "21,72"
  },
  {
    "titre": "Collier pour Airtag",
    "lien": "https://amzn.to/3ZatPle",
    "image": "https://m.media-amazon.com/images/I/71vR2SKGjiL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71prdwlhYTL._AC_SL1500_.jpg",
    "prix": "6,68",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "6,68"
  },
  {
    "titre": "Brosse à vapeur",
    "lien": "https://amzn.to/3ZdK8O1",
    "image": "https://m.media-amazon.com/images/I/61Dm2VIBMeL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/61xLQciWHCL._AC_SL1500_.jpg",
    "prix": "7,19",
    "isBlackFriday": true,
    "percentBlackFriday": "20,00",
    "PrixBeforePromo": "8,99"
  },
  {
    "titre": "Jouet à griffer",
    "lien": "https://amzn.to/4idAlQQ",
    "image": "https://m.media-amazon.com/images/I/818P0g1dGwL._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71mcSwB2+XL._AC_SL1500_.jpg",
    "prix": "11,00",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "11,00"
  },
  {
    "titre": "Arbre à chat",
    "lien": "https://amzn.to/3CQPcAs",
    "image": "https://m.media-amazon.com/images/I/810NQPqAv5L._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/81jF7wNIuhL._AC_SL1500_.jpg",
    "prix": "39,99",
    "isBlackFriday": true,
    "percentBlackFriday": "26,00",
    "PrixBeforePromo": "54,04"
  },
  {
    "titre": "Sac à dos pour chat",
    "lien": "https://amzn.to/4i9u20y",
    "image": "https://m.media-amazon.com/images/I/51+O3h8xM-L._AC_SL1000_.jpg",
    "image2": "https://m.media-amazon.com/images/I/51flCXRSzNL._AC_SL1000_.jpg",
    "prix": "68,99",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "68,99"
  },
  {
    "titre": "Tapis tunnel pour chat",
    "lien": "https://amzn.to/3ZcOZz9",
    "image": "https://m.media-amazon.com/images/I/71xcWSvFLML._AC_SL1500_.jpg",
    "image2": "https://m.media-amazon.com/images/I/71CfNsaInfL._AC_SL1500_.jpg",
    "prix": "31,98",
    "isBlackFriday": false,
    "percentBlackFriday": "",
    "PrixBeforePromo": "31,98"
  }
]