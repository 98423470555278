import { useState, useEffect } from 'react';
import { cadeaux } from '../data/cadeaux';
import Header from '../components/Header';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';

function Produits() {
    
      const [filtrePrix, setFiltrePrix] = useState(null);
    
      
    
      const filtrerProduits = (produit) => {
        if (!filtrePrix) return true;
        
        // Convertir le prix en nombre
        const prixNumerique = parseFloat(produit.prix);
        
        switch (filtrePrix) {
          case '0-10':
            return prixNumerique < 10;
          case '10-20':
            return prixNumerique >= 10 && prixNumerique < 20;
          case '20-30':
            return prixNumerique >= 20 && prixNumerique < 30;
          case '30-50':
            return prixNumerique >= 30 && prixNumerique < 50;
          case '50+':
            return prixNumerique >= 50;
          default:
            return true;
        }
      };

  return (
    <>
      <Helmet>
        <title>Catalogue Accessoires et Jouets Chat | Pour Mon Chat</title>
        <meta name="description" content="Large gamme d'accessoires pour chat : arbres à chat, jouets, fontaines à eau, griffoirs. Trouvez l'équipement parfait pour le confort de votre chat." />
        <link rel="canonical" href="https://www.pourmonchat.fr/produits" />
      </Helmet>

      <main>
      <Header />
        <div className="catalogue-title">
          <h2>Catalogue Complet d'Accessoires pour Chat</h2>
        </div>
        
        <section className="filtres-section">
          <h2>Filtrer par Prix</h2>
          <div className="filtres-prix">
            <button 
              className={`filtre-btn ${filtrePrix === '0-10' ? 'actif' : ''}`}
              onClick={() => setFiltrePrix(filtrePrix === '0-10' ? null : '0-10')}
            >
              - 10€
            </button>
            <button 
              className={`filtre-btn ${filtrePrix === '10-20' ? 'actif' : ''}`}
              onClick={() => setFiltrePrix(filtrePrix === '10-20' ? null : '10-20')}
            >
              10€ - 20€
            </button>
            <button 
              className={`filtre-btn ${filtrePrix === '20-30' ? 'actif' : ''}`}
              onClick={() => setFiltrePrix(filtrePrix === '20-30' ? null : '20-30')}
            >
              20€ - 30€
            </button>
            <button 
              className={`filtre-btn ${filtrePrix === '30-50' ? 'actif' : ''}`}
              onClick={() => setFiltrePrix(filtrePrix === '30-50' ? null : '30-50')}
            >
              30€ - 50€
            </button>
            <button 
              className={`filtre-btn ${filtrePrix === '50+' ? 'actif' : ''}`}
              onClick={() => setFiltrePrix(filtrePrix === '50+' ? null : '50+')}
            >
              + 50€
            </button>
          </div>
        </section>

        <section className="produits-container">
          <h2>Nos Accessoires et Jouets pour Chat</h2>
          <div className="produits-grid">
            {cadeaux.filter(filtrerProduits).map((cadeau, index) => (
              <article className="carte-produit" key={index}>
                <a 
                  href={cadeau.lien} 
                  target="_blank" 
                  rel="sponsored noopener" 
                  className="produit-link"
                >
                  <div className="image-container">
                    {cadeau.isBlackFriday && (
                      <div className="bandeau-blackfriday">
                        Offre Black Friday
                      </div>
                    )}
                    <img 
                      src={cadeau.image} 
                      alt={`${cadeau.titre} pour chat - Accessoire félin ${cadeau.isBlackFriday ? `en promotion -${cadeau.percentBlackFriday}%` : ''}`}
                      className="image-produit image-principale"
                      loading="lazy"
                    />
                    {cadeau.image2 && (
                      <img 
                        src={cadeau.image2} 
                        alt={`${cadeau.titre} pour chat - Vue détaillée ${cadeau.isBlackFriday ? `- Promotion Black Friday -${cadeau.percentBlackFriday}%` : ''}`}
                        className="image-produit image-secondaire"
                        loading="lazy"
                      />
                    )}
                  </div>
                </a>
                <div className="produit-info">
                  <div className="produit-details">
                    <div className="titre-container">
                      <h3 className="produit-titre">{cadeau.titre}</h3>
                      {cadeau.isBlackFriday && (
                        <p className="blackfriday-message">Profitez de l'offre Black Friday</p>
                      )}
                    </div>
                    <div className="prix-container">
                      {cadeau.isBlackFriday ? (
                        <>
                          <p className="produit-prix-original">{cadeau.PrixBeforePromo}€</p>
                          <p className="produit-prix">{cadeau.prix}€</p>
                        </>
                      ) : (
                        <p className="produit-prix">{cadeau.prix}€</p>
                      )}
                    </div>
                  </div>
                  <p className="produit-description">{cadeau.description}</p>
                </div>
              </article>
            ))}
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default Produits;
