import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { blog } from '../data/blog';
import '../Blog.css';
import { useNavigate } from 'react-router-dom';

function Blog() {
  const navigate = useNavigate();

  const handleVoirSuite = (postId) => {
    navigate(`/blog/${postId}`);
  };

  return (
    <>
      <Header />
      <div className="blog-container">
        <div className="blog-grid">
          {blog.map((item, index) => (
            <div className="blog-card" key={index}>
              <div className="blog-card-image">
                <img src={item.image} alt={item.titre} />
              </div>
              <div className="blog-card-content">
                <h2>{item.titre}</h2>
                <p>{item.contenu.substring(0, 150)}...</p>
                <button 
                  className="voir-suite" 
                  onClick={() => handleVoirSuite(item.id)}
                >
                  Voir la suite
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
