import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [tempsRestant, setTempsRestant] = useState({
        jours: 0,
        heures: 0,
        minutes: 0,
        secondes: 0
      });

      useEffect(() => {
        const calculerTempsRestant = () => {
          const noel = new Date(new Date().getFullYear(), 11, 25); // 25 décembre
          const maintenant = new Date();
          
          if (maintenant > noel) {
            noel.setFullYear(noel.getFullYear() + 1);
          }
          
          const difference = noel - maintenant;
          
          setTempsRestant({
            jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
            heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            secondes: Math.floor((difference / 1000) % 60)
          });
        };
    
        const timer = setInterval(calculerTempsRestant, 1000);
        return () => clearInterval(timer);
      }, []);

  return (
    <>
        <nav className="navigation">
            <Link to="/">Accueil</Link>
            <Link to="/produits">Nos Produits</Link>
            <Link to="/blackfriday">Black Friday</Link>
            <Link to="/blog">Blog</Link>
        </nav>
        <div className="compte-rebours">
        <p>🎄 Noël arrive dans : {tempsRestant.jours} jours, {tempsRestant.heures} heures, {tempsRestant.minutes} minutes et {tempsRestant.secondes} secondes! 🎅</p>
        </div>
        
        <div className="banniere">
        <img src="/baniere.jpg" alt="Bannière du site" className="banniere-image" />
        <h1 className="banniere-texte">Envie de faire plaisir à votre chat ?</h1>
        </div>
    </>
  );
}

export default Header;
